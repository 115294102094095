import React from 'react'

import PropTypes from 'prop-types'
import {  graphql, StaticQuery } from 'gatsby'

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Layout from '../../components/Layout'

import launch from '../../img/curriculum/launch.jpg'
import ignite from '../../img/curriculum/ignite.jpg'
import propel from '../../img/curriculum/propel.jpg'
import ascend from '../../img/curriculum/ascend.jpg'

import launchSchedule from './launch.json';
import igniteSchedule from './ignite.json';
import propelSchedule from './propel.json';
import ascendSchedule from './ascend.json';

function TabPanel(props) {
  const { children, value, index, data, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
          {data.monday.slots && data.monday.slots.length  ? (
            data.monday.slots.map(slot => {
              return(
                <div>
                  <p>{slot.starttime.substring(11,8)}</p>
                  <p>{slot.endtime.substring(11,8)}</p>
                </div>
              )
            })
          ) : (null)}
          {data.sunday.slots && data.sunday.slots.length  ? (
            data.sunday.slots.map(slot => {
              return(
                <div>
                  <p>Sunday</p>
                  <p>{slot.starttime.substring(11,16)}</p>
                  <p>{slot.endtime.substring(11,16)}</p>
                </div>
              )
            })
          ) : (null)}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default class CurriculumIndexPage extends React.Component {
  state = {
    showSchedule: false,
    value: 0,
    launchSchedule: null,
    igniteSchedule: null,
    propelSchedule: null,
    ascendSchedule: null
  }

  handleClickOpen = () => {
    this.setState({
      showSchedule: true
    })
  };

  handleClose = () => {
    this.setState({
      showSchedule: false
    })
  };

  handleTabs = (event, newValue) => {
    this.setState({
      value : newValue
    })
  }

  componentDidMount () {
    this.setState({
      launchSchedule : launchSchedule,
      igniteSchedule : igniteSchedule,
      propelSchedule : propelSchedule,
      ascendSchedule : ascendSchedule
     })
  }

  render() {
    
      return (
      <Layout isProgramTypeSelected= {true} >

        <section className="section-program">

          {/* Section 1 */}
          <Grid container
            direction="row"
            justify="center"
            className="mb-px-1"
            id="launchToAscent"

          >
            <Grid item xs={12} sm={12} md={6} >
              <h1 className="main-title mt-4 mr-2 text-right mb-mx-0 mb-text-center">Learning takes</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <h1 className="main-title mb-mx-0 mb-text-center">centre stage</h1>
              <h2 className="main-sub-title mb-mx-0 mb-text-center">Launch to Ascend curriculum</h2>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
            className="mb-px-1"

          >
            <Grid item xs={12} sm={12} md={4} >
              
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p className="page-description">The Sesquipedalian language arts and
              personality development curriculum is
              a first of its kind based on a combination
              of techniques outlined in the EL Education
              Framework and Character First Education
              Leadership Programme.
              At Sesquipedalian, we guide our students to
              achieve three theatre-led pillars of high
              performance
                </p>
            </Grid>
          </Grid>

          {/* Section 2 */}
          <Grid container
            direction="row"
            className="mt-5 mb-mt-2 mb-px-1"
          >
            <Grid item xs={12} sm={12} md={4} >
              <h1 className="section-title section-title-mb">Interpretation</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p className="page-description">To develop an analytical mind, advancing from comprehension
               of concepts to complex reasoning. To communicate clearly and eloquently, with a proficiency in language that enriches every aspect of their
                education, work and personal life.
                </p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            className="mt-2 mb-px-1"
          >
            <Grid item xs={12} sm={12} md={4} >
              <h1 className="section-title section-title-mb">Improvisation</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p className="page-description">To be agile and creative, learning to reinvent themselves to work under
              pressure, try new ideas, learn independently and adapt to constantly
              changing situations.
              </p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            className="mt-2 mb-px-1"
          >
            <Grid item xs={12} sm={12} md={4} >
              <h1 className="section-title section-title-mb">Character</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p className="page-description">
                To develop self-image, self-discipline and self-confidence, taking part in
                collaborative efforts with empathy, integrity and compassion.
              </p>
            </Grid>
          </Grid>

          
          <div id="weeklySchedule"></div>
          {/* Section 3 */}
          <Grid container
            direction="row"
            justify="center"
            className="mt-5 mb-mt-2"

          >
            <Grid item xs={12} sm={12} md={4} >
              <h1 className="main-title mt-4 mr-2 text-right mb-mt-0 mb-mx-0 mb-text-center">Play by</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className="mb-flex-col-reverse">
              <h1 className="main-title mb-mx-0 mb-text-center">Play</h1>
              <h2 className="main-sub-title mb-text-center">Progressive development</h2>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            spacing={3}
            className="mb-pt-0 mb-px-1"
          >
            <Grid item xs={12} sm={12} md={4} >
            </Grid>
            <Grid item xs={12} sm={12} md={8} className="mb-pt-0" >
              <div>
              <button variant="contained" color="primary" className="btn btn-primary btm-sm-full-width mb-mt-0"
                onClick={this.handleClickOpen}
              >
                Check Weekly Schedule
              </button>
              <br/>
              <p className="page-description mb-mt-1">
                From the age of 5 and above, students can progress through the programme
                at their own pace, with interactive lessons, engaging academic content
                and live performances at every stage.
              </p>
              </div>

            </Grid>
          </Grid>

          <Grid container
            direction="row"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={9} >
            </Grid>
            <Grid item xs={12} sm={12} md={3} className="mb-px-2" >
              <div className="program-tile bg-secondary box-shadow">
                <h1>Ascend</h1>
                <p>For ages 15-18</p>
              </div>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={6} >
            </Grid>
            <Grid item xs={12} sm={12} md={3} className="mb-px-2">
              <div className="program-tile bg-primary box-shadow">
                <h1>Propel</h1>
                <p>For ages 12-15</p>
              </div>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={3} >
            </Grid>
            <Grid item xs={12} sm={12} md={3} className="mb-px-2">
              <div className="program-tile bg-accent box-shadow">
                <h1>Ignite</h1>
                <p>For ages 8-11</p>
              </div>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={3} className="mb-px-2">
              <div className="program-tile bg-accent-light box-shadow mb-mt-1-5">
                <h1>Launch</h1>
                <p>For ages 4-7</p>
              </div>
            </Grid>
          </Grid>

          {/* Section 4 */}
          <div id="launch"></div>
          <Grid container
            direction="row"
            spacing={3}
            className="mt-3 p-2 bg-accent-light box-shadow border-radius-6 mb-p-0"
          >
            <Grid item xs={12} sm={12} md={3}>
              <h1 className="section-title mb-px-1">Launch</h1>
              <h2 className="section-sub-title mb-px-1">For ages 4-7</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={9} >
              <p className="page-description-lg mb-px-1">
                An exploration-based approach to get started with theatre-led learning, guided by trained facilitators and timeless drama techniques.
                </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <img src={launch} className="program-tile-img" alt="Launch"/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="flex flex-column justify-around mb-px-2" >
              <p className="page-description">
                - Thematic content, stories and literary texts to stimulate creative thinking and character development.
                </p>
              <p className="page-description">
                - Modelling language skills in an immersive environment through drama play.
                </p>
              <p className="page-description">
                - Group activities and projects to encourage self-expression.
                </p>
              <p className="page-description">
                - Simulated drama situation to articulate emotions and respond empathetically.
                </p>
            </Grid>
          </Grid>
          <div id="ignite"></div>
          <Grid container
            direction="row"
            spacing={3}
            className="mt-3 p-2 bg-accent text-white box-shadow border-radius-6 mb-p-0"
          >
            <Grid item xs={12} sm={12} md={3} >
              <h1 className="section-title text-white mb-px-1">Ignite</h1>
              <h2 className="section-sub-title mb-px-1">For ages 8-11</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={9} >
              <p className="page-description-lg mb-px-1">
                An expansion of literary and speech proficiency with real-world applications of theatre techniques such as vocalization and visual communication.
                </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <img src={ignite} className="program-tile-img" alt="Ignite" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="flex flex-column justify-around mb-px-2" >
              <p className="page-description">
                - Literature from different genres to stimulate vocabulary development, comprehension skills and critical thinking.
                </p>
              <p className="page-description">
                - Foundation speech communication skills such as public speaking practice.
                </p>
              <p className="page-description">
                - Research skills and visual aid development for presentations.
                </p>
              <p className="page-description">
                - Reading practices to engage with voice, speech and eye contact.
                </p>
              <p className="page-description">
                - Theatre-led activities such as ensemble building games and script work.
                </p>
              <p className="page-description">
                - Improvisation aimed at building collaboration, creativity and problem-solving strategies.
                </p>
            </Grid>
          </Grid>
          <div id="propel"></div>
          <Grid container
            direction="row"
            spacing={3}
            className="mt-3 p-2 bg-primary text-primary box-shadow border-radius-6 mb-p-0"
          >
            <Grid item xs={12} sm={12} md={3} >
              <h1 className="section-title text-primary mb-px-1">Propel</h1>
              <h2 className="section-sub-title mb-px-1">For ages 12-15</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={9} >
              <p className="page-description-lg mb-px-1">
              An intermediate study of language and leadership, containing activities to improve communication skills and emotional intelligence with theatre-led exercises.
                </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <img src={propel} className="program-tile-img"  alt="Propel"/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="flex flex-column justify-around mb-px-2" >
              <p className="page-description">
                - Literary content to develop word awareness and learn morphological elements.
                </p>
              <p className="page-description">
                - Structured assignments  and collaborative learning to demonstrate use of context.
                </p>
              <p className="page-description">
                - Performance-led activities to draw awareness to emotions and mood.
                </p>
              <p className="page-description">
                - Understanding communication through vocal techniques.
                </p>
                <p className="page-description">
                - Develop research, analysis and debate skills with individual presentations on social, cultural and environmental issues.
                </p>
                <p className="page-description">
                - Six essential leaderships character traits presented through drama-led activities and SEL (Social and Emotional Learning) strategies.
                </p>
            </Grid>
          </Grid>
          <div id="ascend"></div>
          <Grid container
            direction="row"
            spacing={3}
            className="mt-3 p-2 bg-secondary text-white box-shadow border-radius-6 mb-p-0 mb-mb-2"
            
          >
            <Grid item xs={12} sm={12} md={3} >
              <h1 className="section-title text-white mb-px-1">Ascend</h1>
              <h2 className="section-sub-title mb-px-1">For ages 15-18</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={9} >
              <p className="page-description-lg mb-px-1">
               Unlock your full potential and elevate your acting and public speaking credentials
                with our Ascend program, designed exclusively for 15-18 year-olds. This
                advanced course offers specialized online exam training aimed at students who
                are already well-versed in Trinity College London or LAMDA (London Academy
                of Music &amp; Dramatic Art) examinations and are seeking to acquire a diploma
                certificate. Ascend is not just a course; it&#39;s a strategic pathway designed to
                enhance your college applications while setting the stage for a successful career.
                </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <img src={ascend} className="program-tile-img" alt="Ascend" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="flex flex-column justify-around mb-px-2" >
              <p className="page-description">
                - <b>Expert Guidance:</b> Personalized coaching from seasoned professionals
who specialize in Trinity and LAMDA exam preparation.
                </p>
 <p className="page-description">
                - <b>Advanced Techniques:</b> Training in sophisticated acting techniques, voice
modulation, and stage presence to refine your performance skills.
                </p>
 <p className="page-description">
                - <b>Mock Exams:</b> Realistic exam simulations to bolster your confidence and
readiness for the actual certification tests.
                </p>
 <p className="page-description">
                - <b>Strategic Insights:</b> Insider advice on how to leverage your diploma
certification in college applications and beyond.
                </p>
                
            </Grid>
          </Grid>

        </section>

        <Dialog
          open={this.state.showSchedule}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="time-table"
        >
          <DialogTitle className="bg-accent text-white" id="alert-dialog-title" onClose={this.state.handleClose}>{"Weekly Schedule"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {/* <Tabs
              value={this.state.value}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleTabs}
              aria-label="basic tabs example"
            >
              <Tab label="Launch" {...a11yProps(0)} />
              <Tab label="Ignite" {...a11yProps(1)} />
              <Tab label="Propel" {...a11yProps(2)} />
              <Tab label="Ascend" {...a11yProps(3)} />
            </Tabs>

          <TabPanel value={this.state.value} index={0} data={this.state.launchSchedule}>
          </TabPanel>
          <TabPanel value={this.state.value} index={1} data={this.state.igniteSchedule}>
          </TabPanel>
          <TabPanel value={this.state.value} index={2} data={this.state.propelSchedule}>
          </TabPanel>
          <TabPanel value={this.state.value} index={3} data={this.state.ascendSchedule}>
          </TabPanel> */}

             <TableContainer >
                <Table c aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="mb-px-3"></TableCell>
                      <TableCell >Monday</TableCell>
                      <TableCell >Tuesday</TableCell>
                      <TableCell >Wednesday</TableCell>
                      <TableCell >Thursday</TableCell>
                      <TableCell >Friday</TableCell>
                      <TableCell >Saturday</TableCell>
                      <TableCell >Sunday</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="bg-accent-lighter">
                    <TableRow className="mb-schedule-tr">
                      <TableCell className="table-cell-primary mb-schedule-time" >9am - 10am</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell className="table-cell-primary">Propel</TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                    <TableRow className="mb-schedule-tr">
                      <TableCell className="table-cell-accent-light mb-schedule-time" >10am - 11am</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell className="table-cell-accent-light">Launch</TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                    <TableRow className="mb-schedule-tr">
                      <TableCell className="table-cell-accent mb-schedule-time" >10am - 11pm</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell className="table-cell-accent">Ignite</TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                    <TableRow className="mb-schedule-tr">
                      <TableCell className="table-cell-accent-light mb-schedule-time" >3pm - 4pm </TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell className="table-cell-accent-light">Launch</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                    <TableRow className="mb-schedule-tr">
                      <TableCell className="table-cell-accent mb-schedule-time">4pm - 5pm</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                      <TableCell className="table-cell-accent">Ignite</TableCell>
                      <TableCell ></TableCell>
                      <TableCell className="table-cell-accent">Ignite</TableCell>
                      <TableCell ></TableCell>
                      <TableCell ></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer> 

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn"
              onClick={this.handleClose}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      </Layout>
    )
  }
}


// CurriculumIndexPage.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

// export default () => (
//   <StaticQuery
//     query={graphql`
//       query ScheduleQuery {
//         dataJson (monday: {slots: {ne: "[]" }}){
//           programname
//           monday {
//             slots
//           }
//         }
//       }
//     `}
//     render={(data) => <CurriculumIndexPage data={data}/>}
//   />
// )
